.apps-download {
  margin-top: 10px;

  a {
    margin-top: 5px;
    width: 142px;
    text-align: center;
  }

}

.apps-download a, .downloadlink {
  border: none;
}

.downloadlink:visited, .downloadlink:visited span:not(.dln-meta), .textblock .teaser-btn:focus {
  text-decoration: none;
}