.dpag-method {
  line-height: 1.4;
  // TODO: Replace icons with svg
  .media img {
    max-width: 75px;
  }

  // TODO: Refactor cards for method selection
  a.card {
    color: var(--body);
  }

  a.card:hover {
    text-decoration: none;
    border-color: #fc0;
    border-color: var(--primary);
  }

  a.card:hover .chevron {
    -webkit-filter: contrast(0);
    filter: contrast(0);
  }

  a.disabled {
    cursor: not-allowed;
  }
}