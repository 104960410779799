.container-div {
  position: relative;
}

.dpag-language {
  font-weight: bold;
  border-width: 5px;
  position: relative;

  li {
    p {
      display: inline-block;
      min-width: 6rem;
      margin: 0;
    }
  }

  ul {
    li {
      padding: 15px;
    }

    li:hover {
      background: #333333;
      color: white;
    }

    li:hover > p {
      color: white;
    }

    li:first-child:hover {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    li:last-child:hover {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.dpag-language-div {
  position: absolute;
  right: 0;
  top: 10px;
}

.dpag-principal-lang-logo {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: transparent;
}

.dpag-principal-lang-logo > p {
  margin-bottom: 0;
  cursor: context-menu;
}


.dpag-principal-lang-logo-active {
  color: var(--white);
  border-radius: 4px;
  background-color: #333333;
}

#world {
  fill: #000;
  color: #000;
}

.dpag-principal-lang-logo-active #world {
  fill: #fff;
  color: #fff;
}

.dropdown-menu {
  font-weight: normal;
  border-width: 0;
  border-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 0 10px;
  padding: 0;
  left: -50px;
  top: 50px;
}

.dropdown-menu:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 60px;
  border-style: solid;
  border-width: 0 20px 15px 20px;
  border-color: white transparent;
}

.selectedLanguage {
  margin-left: 10px;
}