/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .icon-addon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
  height: 100%;
}

/* align icon */
.right-addon .icon-addon { right: 0px;}

.cursor-pointer { cursor: pointer; }