.pi-ident-success-wrapper {
  h2 {
    margin-bottom: .5rem;
  }
}

.pi-btn--primary {
  color: #000;
  background: #ffd633;
  border-radius: 4px;
  border: none;
}