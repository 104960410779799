.apps-download {
  margin-top: 0px;

  .margin-right {
    margin-right: 10px;
  }
  a {
    display: inline-block;
    position: relative;
    margin-left: 10px;
  }
}

h3.slim {
  max-width: 400px;
}