#autoid-page {
  .clock-wrapper {
    text-align: center;
  }
  @media (min-width: 768px) {
    .clock-wrapper {
      padding-right: 50px;

    }
    .margin-left {
      margin-left: 5rem;
    }
  }

  .clock {
    height: 175px;
  }
}

.iOS {
  right: -30px;
  top: -25px;
  position: absolute;
}



