.fade {
  display: none; // for IE and Edge as they do not seem to respect .fade's opacity settings
}

.tooltip.show {
  left: 50%;
}

.tooltip-inner {
  position: relative;
  left: -50%;
}

.arrow {
  left: -7px;
}