.pi-paragraph--no-max-width {
  max-width: none;
}

.pi-section--center {
  text-align: center;
}

.pi-section {
  margin-bottom: 2rem;
}

.pi-section__content {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}