.btn-section {
  display: flex;
  align-items: baseline;

  button.pi-btn--primary {
    margin-left: auto;
  }
}

button.pi-btn--primary {
  padding: .375rem .75rem;
}

button.pi-btn--disabled {
  background: #d6d6d6;
}