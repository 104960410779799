.face-detection-hint {
  text-align: center;
  width: 60%;
  color: #969393;
  margin: 10px auto 0;

  a {
    color: #969393;
  }
}
