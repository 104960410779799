@media (min-width: 768px) {
  #eid-page .img-fluid {
    margin-top: -63px;
  }

  #eid-page .col-md-2 {
    margin-right: 48px;
  }

  #eid-page .col-md-3 > div {
    width: 185px;
  }

  #eid-page .qr {
    margin-top: 4.5rem !important;
  }
}

@media (max-width: 770px) {
  .showcase-section {
    text-align: center;
  }
}